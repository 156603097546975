<script setup>
import { useAuthStore } from '~~/store/auth';

const runtimeConfig = useRuntimeConfig();
const router = useRouter();
const store = useAuthStore();

const isLoading = ref(false);
const hasError = ref(false);
const errorMsg = ref('');
const model = reactive({
  email: '',
  password: ''
});

function onSubmit(event) {
  event.preventDefault()
  
  isLoading.value = true;
  hasError.value = false;

  $fetch(`${runtimeConfig.public.api.endpoint}/login`, { 
    method: 'POST',
    body: model,
  })
  .then((response) => {
    if (!response.ok) {
      isLoading.value = false;
      hasError.value = true;
    }
    return response;
  })
  .then((response) => {
    store.login(response.data);
    router.push('/');
  })
  .catch((error) => {
    isLoading.value = false;
    hasError.value = true;
    errorMsg.value = error.message;
    console.error(error, error.message)
  });
}

</script>

<template>
  <div class="login">
    <h1 class="login__title page-title">Sign in</h1>
    <form class="login__form" v-on:submit="onSubmit">
      <p class="login__form-msg" v-if="hasError">An error occurred:</p>
      <p class="login__form-msg" v-if="hasError">{{ errorMsg }}</p>
      <UiInput label="Email" v-model="model.email" />
      <UiInput type="password" label="Password" v-model="model.password" />
      <UiButton class="login__action" v-bind:isLoading="isLoading">Login</UiButton>
    </form>
  </div>
</template>

<style lang="scss">
.login__form {
  width: 41.6%;

  @include breakpoint('tablet') {
    width: 50%;
  }

  @include breakpoint('phone') {
    width: 100%;
  }
}

.login__action {
  margin-top: var(--s-stack--s);
}
</style>
